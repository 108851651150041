import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { PrismicRichText } from '@prismicio/react';
import { Spinner } from 'reactstrap';

import AddButton from '../../Registration/RegistrationForm/styled/AddButton';
import {
  fetchBrokerageAccounts,
  fetchBrokerages
} from '../../../../state/actions/brokerageAccountActions';
import BrokerageAccountForm from './BrokerageAccountForm';
import SpinnerContainer from '../../../SpinnerContainer';
import EditButton from '../../Registration/RegistrationForm/styled/EditButton';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
  padding-bottom: 8px;
  margin-top: 40px;

  h3 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
  }
`;

const AccountListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${prop('theme.colors.titleGrey')};
  line-height: 24px;
  font-weight: ${prop('theme.fontWeights.medium')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const AccountList = styled.div`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 16px 0 32px;
  }
`;

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
`;

const BrokerageAccount = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${prop('theme.colors.white')};
  border-radius: 16px;
  border: 1px solid ${prop('theme.colors.inputGrey')};
  font-weight: ${prop('theme.fontWeights.medium')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const IdentifierContainer = styled.div`
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex: 1 1 60%;
  min-width: 60%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex: 1 1 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 8px;
    }
  }
`;

const TagContainer = styled.div`
  flex: 1 1 40%;
  min-width: 40%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex: 1 1 100%;
    min-width: 100%;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const Tag = styled.div`
  border: 1px solid ${prop('theme.colors.tagBorderGrey')};
  border-radius: 8px;
  padding: 2px 16px;
  font-size: 0.75rem;
  width: max-content;

  &:last-child {
    margin-left: 8px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    &:last-child {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

const EmptyListMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 42px 0;
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = { data: {} };

function BrokerageAccountsDisplay({ data }) {
  const brokerageAccounts = useSelector((state) =>
    get(state, 'memberInfo.brokerageAccounts', [])
  );
  const dispatch = useDispatch();
  const [brokerageAccountLoading, setBrokerageAccountLoading] = useState(false);
  const [brokerageLoading, setBrokerageLoading] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [currentlyEditingIndex, setCurrentlyEditingIndex] = useState(-1);
  const addFormRef = useRef();

  const { title } = data.primary;

  useEffect(() => {
    setBrokerageAccountLoading(true);
    setBrokerageLoading(true);
    dispatch(fetchBrokerageAccounts(() => setBrokerageAccountLoading(false)));
    dispatch(fetchBrokerages(() => setBrokerageLoading(false)));
  }, []);

  useEffect(() => {
    if (
      isAddingAccount &&
      typeof window !== 'undefined' &&
      addFormRef.current
    ) {
      addFormRef.current.scrollIntoView();
    }
  }, [isAddingAccount]);

  let accountListComponent;

  if (brokerageLoading || brokerageAccountLoading) {
    accountListComponent = (
      <SpinnerContainer>
        <Spinner style={{ width: '64px', height: '64px' }} />
      </SpinnerContainer>
    );
  } else if (brokerageAccounts.length === 0 && !isAddingAccount) {
    accountListComponent = (
      <EmptyListMessage>You have not added any accounts yet</EmptyListMessage>
    );
  } else {
    accountListComponent = brokerageAccounts.map((account, index) => {
      const {
        account_identifier,
        brokerage,
        futures_enabled,
        retirement_account
      } = account;
      return (
        <AccountList key={`brokerageAccount-${account.id}`}>
          {currentlyEditingIndex === index ? (
            <BrokerageAccountForm
              brokerageAccount={account}
              finishEditingAction={() => setCurrentlyEditingIndex(-1)}
            />
          ) : (
            <AccountContainer>
              <BrokerageAccount>
                <IdentifierContainer>
                  <div>{brokerage.name}</div>
                  <div>{account_identifier}</div>
                </IdentifierContainer>
                <TagContainer>
                  {futures_enabled && <Tag>FUTURES ENABLED</Tag>}
                  {retirement_account && <Tag>RETIREMENT ACCOUNT</Tag>}
                </TagContainer>
              </BrokerageAccount>
              {currentlyEditingIndex === -1 && !isAddingAccount && (
                <EditButton
                  onClick={() => {
                    setCurrentlyEditingIndex(index);
                  }}
                />
              )}
            </AccountContainer>
          )}
        </AccountList>
      );
    });
  }

  return (
    <>
      <Header>
        <PrismicRichText field={title.richText} />
        <AddButton
          onClick={() => {
            setIsAddingAccount(true);
          }}
          disabled={
            brokerageLoading ||
            brokerageAccountLoading ||
            isAddingAccount ||
            currentlyEditingIndex > -1
          }
        >
          Add Account
        </AddButton>
      </Header>
      {brokerageAccounts.length > 0 && (
        <AccountListHeader>
          <IdentifierContainer>
            <div>Brokerage Name</div>
            <div>Account ID</div>
          </IdentifierContainer>
          <TagContainer>
            <div>Types of Account</div>
          </TagContainer>
        </AccountListHeader>
      )}
      {accountListComponent}
      {isAddingAccount && (
        <BrokerageAccountForm
          ref={addFormRef}
          finishEditingAction={() => setIsAddingAccount(false)}
        />
      )}
    </>
  );
}

BrokerageAccountsDisplay.propTypes = propTypes;
BrokerageAccountsDisplay.defaultProps = defaultProps;

export default BrokerageAccountsDisplay;
