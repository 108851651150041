import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import editIcon from '../../../../../images/icons/edit-icon.svg';

const StyledButton = styled.button`
  align-self: center;
  border: none;
  background: none;
  padding: 0;
  position: absolute;
  right: -40px;

  @media (max-width: ${prop('theme.breakpoints.xxl')}) {
    right: -27px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    right: 30px;
  }
`;

const propTypes = { onClick: PropTypes.func };

const defaultProps = { onClick: () => {} };

function EditButton({ onClick }) {
  return (
    <StyledButton type='button' onClick={onClick}>
      <img src={editIcon} height='16' width='16' />
    </StyledButton>
  );
}

EditButton.propTypes = propTypes;
EditButton.defaultProps = defaultProps;

export default EditButton;
