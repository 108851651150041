import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import InputRow from '../../Registration/RegistrationForm/styled/InputRow';
import Checkbox from '../../Registration/RegistrationForm/Checkbox';
import Select from '../../Registration/RegistrationForm/Select';
import {
  createBrokerageAccount,
  removeBrokerageAccount,
  updateBrokerageAccount
} from '../../../../state/actions/brokerageAccountActions';
import Input from '../../Registration/RegistrationForm/Input';
import StyledForm from '../../Registration/RegistrationForm/styled/StyledForm';
import {
  ButtonLinkBlackOutlineStyles,
  ButtonLinkStyles
} from '../../../ButtonLink';
import ErrorAlert from '../../Registration/RegistrationStepPageBody/ErrorAlert';

const AccountForm = styled(StyledForm)`
  margin-bottom: 24px;
`;

const BottomRow = styled(InputRow)`
  margin-top: 16px;
  align-items: center;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    &&& {
      margin-left: 0;
    }
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;

    &&& {
      input {
        margin-bottom: 16px;
        width: 100%;
        font-size: 1.375rem;
        min-height: 56px;
      }
    }
  }
`;

const LinkButton = styled.button`
  border: none;
  background-color: initial;
  padding: 0;
  color: ${prop('theme.colors.redPrimary')};
  font-size: 1rem;
  margin-right: 16px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-right: 0;
    order: 3;
    margin-top: 16px;
    font-size: 1.375rem;
  }
`;

const Button = styled.button`
  ${ButtonLinkStyles}
  ${ButtonLinkBlackOutlineStyles}

  min-width: 200px;
  margin-right: 16px;
  background-color: ${prop('theme.colors.white')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    order: 2;
    margin-right: 0;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    margin-bottom: 16px;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;
    > * {
      margin-bottom: 16px;
    }
  }
`;

const propTypes = {
  brokerageAccount: PropTypes.object,
  finishEditingAction: PropTypes.func
};

const defaultProps = { brokerageAccount: null, finishEditingAction: () => {} };

const BrokerageAccountForm = forwardRef(
  ({ brokerageAccount, finishEditingAction }, ref) => {
    const {
      register,
      handleSubmit,
      setValue,
      control,
      formState: { isValid, errors }
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const brokerages = useSelector((state) =>
      get(state, 'memberInfo.brokerages', [])
    );
    const formattedBrokerages = brokerages.map((brokerage) => ({
      value: brokerage.id,
      label: brokerage.name
    }));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [futuresEnabledValue, setFuturesEnabledValue] = useState(
      brokerageAccount ? brokerageAccount.futures_enabled : undefined
    );
    const [retirementAccountValue, setRetirementAccountValue] = useState(
      brokerageAccount ? brokerageAccount.retirement_account : undefined
    );

    const onSubmit = (data) => {
      setIsSubmitting(true);
      if (brokerageAccount) {
        const newBrokerageAccount = {
          ...brokerageAccount,
          brokerage_id: data.broker.value,
          account_identifier: data.accountId,
          futures_enabled: data.futuresEnabled,
          retirement_account: data.retirementAccount
        };

        dispatch(
          updateBrokerageAccount(
            newBrokerageAccount,
            finishEditingAction,
            () => {
              setIsSubmitting(false);
            }
          )
        );
      } else {
        const newBrokerageAccount = {
          brokerage_id: data.broker.value,
          account_identifier: data.accountId,
          futures_enabled: data.futuresEnabled,
          retirement_account: data.retirementAccount
        };

        dispatch(
          createBrokerageAccount(
            newBrokerageAccount,
            finishEditingAction,
            () => {
              setIsSubmitting(false);
            }
          )
        );
      }
    };

    const removeAccount = (event, account) => {
      event.preventDefault();

      setIsSubmitting(true);
      dispatch(
        removeBrokerageAccount(account, finishEditingAction, () =>
          setIsSubmitting(false)
        )
      );
    };

    const defaultBroker = brokerageAccount
      ? {
          value: brokerageAccount.brokerage.id,
          label: brokerageAccount.brokerage.name
        }
      : null;

    return (
      <div ref={ref}>
        <ErrorAlert customMargin='24px 0 16px' />
        <AccountForm onSubmit={handleSubmit(onSubmit)}>
          <InputRow>
            <Select
              name='broker'
              control={control}
              placeholder='Choose Broker'
              options={formattedBrokerages}
              setValue={setValue}
              defaultValue={defaultBroker}
              rules={{ required: true }}
              $minWidth={312}
            />
            <Input
              type='text'
              name='accountId'
              labelText='Account Identifier'
              defaultValue={
                brokerageAccount && brokerageAccount.account_identifier
              }
              error={errors.accountId}
              {...register('accountId', {
                required: 'Account Identifier is required'
              })}
            />
          </InputRow>
          <BottomRow>
            <CheckboxContainer>
              <Checkbox
                label='Futures Enabled'
                checked={futuresEnabledValue}
                onClick={() => setFuturesEnabledValue((prev) => !prev)}
                error={errors.futuresEnabled}
                {...register('futuresEnabled')}
              />
              <Checkbox
                label='Retirement Account'
                checked={retirementAccountValue}
                onClick={() => setRetirementAccountValue((prev) => !prev)}
                error={errors.retirementAccount}
                {...register('retirementAccount')}
              />
            </CheckboxContainer>
            <ButtonContainer>
              <LinkButton type='button' onClick={finishEditingAction}>
                Cancel
              </LinkButton>
              {brokerageAccount && (
                <Button
                  type='button'
                  onClick={(event) => removeAccount(event, brokerageAccount)}
                >
                  Delete
                </Button>
              )}
              <Input
                type='submit'
                value='Save'
                disabled={!isValid || isSubmitting}
              />
            </ButtonContainer>
          </BottomRow>
        </AccountForm>
      </div>
    );
  }
);

BrokerageAccountForm.propTypes = propTypes;
BrokerageAccountForm.defaultProps = defaultProps;

export default BrokerageAccountForm;
