import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';

export const fetchBrokerageAccounts = (completedCallback) => async (
  dispatch
) => {
  try {
    const response = await subscriberApiClient.get(
      '/subscriber/brokerage_accounts'
    );

    dispatch({
      type: actions.SET_BROKERAGE_ACCOUNTS,
      payload: response.data.brokerage_accounts
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.BROKERAGE_ACCOUNT_FETCH
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.BROKERAGE_ACCOUNT_FETCH,
        message: getErrorMessage(error)
      }
    });
  }

  completedCallback();
};

export const fetchBrokerages = (completedCallback) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/brokerages');
    const brokerages = response.data.brokerages;

    dispatch({
      type: actions.SET_BROKERAGES,
      payload: brokerages
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.BROKERAGE_FETCH
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.BROKERAGE_FETCH,
        message: getErrorMessage(error)
      }
    });
  }

  completedCallback();
};

export const createBrokerageAccount = (
  brokerageAccount,
  successCallback,
  completedCallback
) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.post(
      '/subscriber/brokerage_accounts',
      { brokerage_account: brokerageAccount }
    );

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.BROKERAGE_ACCOUNT_EDIT
    });

    dispatch({
      type: actions.ADD_BROKERAGE_ACCOUNT,
      payload: response.data.brokerage_account
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.BROKERAGE_ACCOUNT_EDIT,
        message:
          'There was an error creating this account. Please refresh the page and try again later.'
      }
    });
  }

  completedCallback();
};

export const updateBrokerageAccount = (
  brokerageAccount,
  successCallback,
  completedCallback
) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.patch(
      `/subscriber/brokerage_accounts/${brokerageAccount.id}`,
      { brokerage_account: brokerageAccount }
    );

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.BROKERAGE_ACCOUNT_EDIT
    });

    dispatch({
      type: actions.UPDATE_BROKERAGE_ACCOUNT,
      payload: response.data.brokerage_account
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.BROKERAGE_ACCOUNT_EDIT,
        message:
          'There was an error updating this account. Please refresh the page and try again later.'
      }
    });
  }

  completedCallback();
};

export const removeBrokerageAccount = (
  brokerageAccount,
  successCallback,
  completedCallback
) => async (dispatch) => {
  try {
    await subscriberApiClient.delete(
      `/subscriber/brokerage_accounts/${brokerageAccount.id}`
    );

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.BROKERAGE_ACCOUNT_EDIT
    });

    dispatch({
      type: actions.REMOVE_BROKERAGE_ACCOUNT,
      payload: brokerageAccount
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.BROKERAGE_ACCOUNT_EDIT,
        message:
          'There was an error removing this account. Please refresh the page and try again later.'
      }
    });
  }

  completedCallback();
};
